import currency from "./currency";
import {
  indexOf,
  isNumber,
} from "lodash-es";

export default function(value, units = "KB") {
  let valueLocal = value;
  if (!isNumber(value)) {
    valueLocal = 0;
  }
  const k = 1024;
  const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let selectedSize = indexOf(SIZES, units);
  if (selectedSize === -1) {
    selectedSize = Math.floor(Math.log(value) / Math.log(k));
  }
  selectedSize = Math.max(1, selectedSize);
  return `${ currency(valueLocal / Math.pow(k, selectedSize), "") } ${ SIZES[selectedSize] }`;
}
