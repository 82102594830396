import FiltersAPI from "../../../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "UiAbschreibungsratenReadOnly",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
};
