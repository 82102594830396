import mainErrorCallbacks from "./mainErrorCallbacks";
import router from "./Router/Router";

import {
  forEach,
} from "lodash-es";

export default {
  baseUrl: "/api/",
  axiosCreateOptions: {
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
  },
  errorCallbacks: mainErrorCallbacks({ router }),
  setUrlForArray: setUrlForArray,
};

function setUrlForArray({ array, url, id }) {
  if (!array.length) {
    return url;
  }
  let URL = url || "";
  forEach(array, v => {
    if (URL) {
      URL += "&";
    }
    URL += `${ id }=${ v }`;
  });
  return URL;
}
