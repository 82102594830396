import UIReadOnlyMixin from "../../UIReadOnlyMixin";
import {
  FilterIbanMixin,
} from "../../../../mixins/FiltersMixin";

// @vue/component
export default {
  name: "UiIbanReadOnly",
  mixins: [
    FilterIbanMixin,
    UIReadOnlyMixin,
  ],
  computed: {
    getModel() {
      return this.filterIban(this.model);
    },
  },
};
