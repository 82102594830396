import ATinymce from "aloha-vue/src/ui/ATinymce/ATinymce";

import UIComponentMixin from "../UIComponentMixin";

import ContentAPI from "./compositionAPI/ContentAPI";
import EventsAPI from "./compositionAPI/EventsAPI";
import IdAPI from "./compositionAPI/IdAPI";
import ToolbarAPI from "./compositionAPI/ToolbarAPI";

// @vue/component
export default {
  name: "UiTinymce",
  components: {
    ATinymce,
  },
  mixins: [
    UIComponentMixin,
  ],
  setup(props) {
    const {
      contentLangs,
      contentStyle,
    } = ContentAPI();

    const {
      toolbar,
    } = ToolbarAPI(props);

    const {
      onBlurLocal,
      onFocusLocal,
      onInputLocal,
    } = EventsAPI(props);

    const {
      htmlId,
    } = IdAPI(props);

    return {
      contentLangs,
      contentStyle,
      htmlId,
      onBlurLocal,
      onFocusLocal,
      onInputLocal,
      toolbar,
    };
  },
};
