const AufgabeList = () => import(/* webpackChunkName: "AufgabeList" */ "../../views/Aufgabe/AufgabeList/AufgabeList.vue");
const AufgabenvorlagenDetails = () => import(/* webpackChunkName: "AufgabenvorlagenDetails" */ "../../views/Aufgabe/AufgabenvorlagenDetails/AufgabenvorlagenDetails.vue");
const KAufgabeList = () => import(/* webpackChunkName: "KAufgabeList" */ "../../views/Aufgabe/KAufgabeList/KAufgabeList.vue");
const Termine = () => import(/* webpackChunkName: "Termine" */ "../../views/Termine/Termine.vue");
const WorkflowfolgeclientfunktionenDetails = () => import(/* webpackChunkName: "WorkflowfolgeclientfunktionenDetails" */ "../../views/Workflowfolgeclientfunktionen/WorkflowfolgeclientfunktionenDetails/WorkflowfolgeclientfunktionenDetails.vue");
const WorkflowfolgeclientfunktionenList = () => import(/* webpackChunkName: "WorkflowfolgeclientfunktionenList" */ "../../views/Workflowfolgeclientfunktionen/WorkflowfolgeclientfunktionenList/WorkflowfolgeclientfunktionenList.vue");
const WorkflowfolgenDetails = () => import(/* webpackChunkName: "WorkflowfolgenDetails" */ "../../views/Workflowfolgen/WorkflowfolgenDetails/WorkflowfolgenDetails.vue");
const WorkflowfolgenList = () => import(/* webpackChunkName: "WorkflowfolgenList" */ "../../views/Workflowfolgen/WorkflowfolgenList/WorkflowfolgenList.vue");
const WorkflowfolgeserverfunktionenDetails = () => import(/* webpackChunkName: "WorkflowfolgeserverfunktionenDetails" */ "../../views/Workflowfolgeserverfunktionen/WorkflowfolgeserverfunktionenDetails/WorkflowfolgeserverfunktionenDetails.vue");
const WorkflowfolgeserverfunktionenList = () => import(/* webpackChunkName: "WorkflowfolgeserverfunktionenList" */ "../../views/Workflowfolgeserverfunktionen/WorkflowfolgeserverfunktionenList/WorkflowfolgeserverfunktionenList.vue");
const WorkflowfolgestatusfunktionenDetails = () => import(/* webpackChunkName: "WorkflowfolgestatusfunktionenDetails" */ "../../views/Workflowfolgestatusfunktionen/WorkflowfolgestatusfunktionenDetails/WorkflowfolgestatusfunktionenDetails.vue");
const WorkflowfolgestatusfunktionenList = () => import(/* webpackChunkName: "WorkflowfolgestatusfunktionenList" */ "../../views/Workflowfolgestatusfunktionen/WorkflowfolgestatusfunktionenList/WorkflowfolgestatusfunktionenList.vue");
const WorkflowsDetails = () => import(/* webpackChunkName: "WorkflowsList" */ "../../views/Workflows/WorkflowsDetails/WorkflowsDetails.vue");
const WorkflowsList = () => import(/* webpackChunkName: "WorkflowsList" */ "../../views/Workflows/WorkflowsList/WorkflowsList.vue");
const WorkflowtypDetails = () => import(/* webpackChunkName: "WorkflowtypDetails" */ "../../views/Workflowtypen/WorkflowtypDetails/WorkflowtypDetails.vue");
const WorkflowtypenList = () => import(/* webpackChunkName: "WorkflowtypenList" */ "../../views/Workflowtypen/WorkflowtypenList/WorkflowtypenList.vue");

export default [
  {
    path: "/workflows/",
    name: "root.workflows",
    component: WorkflowsList,
    meta: {
      permissions: [
        "workflow.ui",
      ],
    },
  },
  {
    path: "/workflows/:id/",
    name: "root.workflows.details",
    component: WorkflowsDetails,
    meta: {
      permissions: [
        "workflow.ui",
      ],
    },
  },
  {
    path: "/termine/",
    name: "root.termine",
    component: Termine,
  },
  {
    path: "/workflowtypen/",
    name: "root.workflowtypen",
    component: WorkflowtypenList,
    meta: {
      permissions: [
        "workflowtyp.ui",
      ],
    },
  },
  {
    path: "/workflowtypen/:id/",
    name: "root.workflowtypen.detail",
    component: WorkflowtypDetails,
    meta: {
      permissions: [
        "workflowtyp.ui",
      ],
    },
  },
  {
    path: "/workflowfolgen/",
    name: "root.workflowfolgen",
    component: WorkflowfolgenList,
    meta: {
      permissions: [
        "workflowfolge.ui",
      ],
    },
  },
  {
    path: "/workflowfolgen/:id/",
    name: "root.workflowfolgen.detail",
    component: WorkflowfolgenDetails,
    meta: {
      permissions: [
        "workflowfolge.ui",
      ],
    },
  },
  {
    path: "/workflowfolgeclientfunktionen/",
    name: "root.workflowfolgeclientfunktionen",
    component: WorkflowfolgeclientfunktionenList,
    meta: {
      permissions: [
        "clientfunktion.ui",
      ],
    },
  },
  {
    path: "/workflowfolgeclientfunktionen/:id/",
    name: "root.workflowfolgeclientfunktionen.detail",
    component: WorkflowfolgeclientfunktionenDetails,
    meta: {
      permissions: [
        "clientfunktion.ui",
      ],
    },
  },
  {
    path: "/workflowfolgeserverfunktionen/",
    name: "root.workflowfolgeserverfunktionen",
    component: WorkflowfolgeserverfunktionenList,
    meta: {
      permissions: [
        "serverfunktion.ui",
      ],
    },
  },
  {
    path: "/workflowfolgeserverfunktionen/:id/",
    name: "root.workflowfolgeserverfunktionen.detail",
    component: WorkflowfolgeserverfunktionenDetails,
    meta: {
      permissions: [
        "serverfunktion.ui",
      ],
    },
  },
  {
    path: "/workflowfolgestatusfunktionen/",
    name: "root.workflowfolgestatusfunktionen",
    component: WorkflowfolgestatusfunktionenList,
    meta: {
      permissions: [
        "statusfunktion.ui",
      ],
    },
  },
  {
    path: "/workflowfolgestatusfunktionen/:id/",
    name: "root.workflowfolgestatusfunktionen.detail",
    component: WorkflowfolgestatusfunktionenDetails,
    meta: {
      permissions: [
        "statusfunktion.ui",
      ],
    },
  },
  {
    path: "/aufgaben/",
    name: "root.aufgabe",
    component: AufgabeList,
    meta: {
      permissions: [
        "aufgabe.view",
      ],
    },
  },
  {
    path: "/aufgabenvorlagen/",
    name: "root.kaufgaben",
    component: KAufgabeList,
    meta: {
      permissions: [
        "kaufgabe.ui",
      ],
    },
  },
  {
    path: "/aufgabenvorlagen/:id/",
    name: "root.kaufgaben.detail",
    component: AufgabenvorlagenDetails,
    meta: {
      permissions: [
        "kaufgabe.ui",
      ],
    },
  },
];
