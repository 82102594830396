import {
  computed,
  ref,
} from "vue";

import * as Sentry from "@sentry/vue";

import {
  getHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";

import {
  EventBus,
} from "../functions/event-bus";
import {
  cloneDeep,
  isEmpty,
} from "lodash-es";

export const me = ref(undefined);
const warMeLoaded = ref(false);

export const isAuthenticated = computed(() => {
  return !!(me.value && me.value.pk);
});

export const user = computed(() => {
  return me.value;
});

export default function UserAPI() {
  return {
    getUser,
    isAuthenticated,
    me,
    setMe,
    setUser,
    updateUser,
    user,
  };
}

export function updateUser() {
  return getUser({ isReload: false });
}

export function getUser({ isReload = false } = {}) {
  if (window.userPromise && !window.userPromiseIsFulfilled && !isReload) {
    return window.userPromise;
  }
  window.userPromise = new Promise((resolve, reject) => {
    window.userPromiseIsFulfilled = false;
    if (warMeLoaded.value && !isReload) {
      window.userPromiseIsFulfilled = true;
      return resolve(me.value);
    }
    return getHttp({
      url: "profil/",
    }).then(
      response => {
        setMe(response);
        warMeLoaded.value = true;
        EventBus.$emit("updateProfile");
        return resolve(response);
      },
      error => {
        return reject(error);
      }
    );
  }).finally(() => {
    window.userPromiseIsFulfilled = true;
  });
  return window.userPromise;
}

export function setMe(_me) {
  me.value = cloneDeep(_me);
  if (!isEmpty(_me)) {
    Sentry.setUser({
      id: _me.n_id,
      username: _me.n_loginname,
      email: _me.n_email
    });
  } else {
    Sentry.setUser(null);
  }
}

export function setUser(_user) {
  me.value = cloneDeep(_user);
}
