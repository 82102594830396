// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 992px) {
.the_navbar_center {
        font-size: 20px;
}
.the_navbar_left,
    .the_navbar_right {
        white-space: nowrap;
}
}
@media (min-width: 1200px) {
.the_navbar_center {
        font-size: 25px;
}
}
`, "",{"version":3,"sources":["webpack://./client/src/SingleUsageComponents/TheNavbar/TheNavbar.css"],"names":[],"mappings":"AAAA;AACA;QACQ,eAAe;AACvB;AACA;;QAEQ,mBAAmB;AAC3B;AACA;AACA;AACA;QACQ,eAAe;AACvB;AACA","sourcesContent":["@media (min-width: 992px) {\n.the_navbar_center {\n        font-size: 20px;\n}\n.the_navbar_left,\n    .the_navbar_right {\n        white-space: nowrap;\n}\n}\n@media (min-width: 1200px) {\n.the_navbar_center {\n        font-size: 25px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
