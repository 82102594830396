import HttpMixin from "../../../../../global/mixins/HttpMixin";
import JsonOptionsMixin from "./JsonOptionsMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import UiValidatedJson from "../../../../../global/components/ui/UiValidatedJson/UiValidatedJson.vue";

// @vue/component
export default {
  name: "UiAuszahlungsmodi",
  components: {
    UiValidatedJson,
  },
  mixins: [
    HttpMixin,
    JsonOptionsMixin,
    UIComponentMixin,
  ],
};
