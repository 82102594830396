import {
  createRouter,
  createWebHistory,
} from "vue-router";

const ErrorsRedirectTo404 = () => import(/* webpackChunkName: "ErrorsRedirectTo404" */ "../views/Errors/ErrorsRedirectTo404/ErrorsRedirectTo404");

import {
  getUser,
  isAuthenticated,
  user,
} from "../global/compositionAPI/UserAPI";
import {
  checkPermissionsSync,
} from "../global/compositionAPI/PermissionAPI";
import {
  loadSyConfig,
  syConfig,
} from "../global/compositionAPI/SyConfigAPI";
import {
  addNotification,
} from "../global/compositionAPI/NotificationAPI";


import Routes from "./Routes/index";
import {
  targetRoute,
} from "../global/functions/ErrorListenerForCodeSplitting";
import {
  get,
} from "lodash-es";
import {
  EventBus,
} from "../global/functions/event-bus";

const PASSWORT_ABGELAUFEN_STATUS_ID = "2d4d03f5-6c2e-4ebe-b612-bdc544582739";

const ROUTES = [
  ...Routes,
  {
    // If the routing configuration '*' reports an error, replace it with '/: catchAll(. *)'
    // caught Error: Catch all routes ("*") must now be defined using a param with a custom regexp
    path: "/:catchAll(.*)", // Special attention should be paid to the bottom
    component: ErrorsRedirectTo404,
  }
];

const ROUTER = createRouter({
  history: createWebHistory("/"),
  routes: ROUTES,
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0,
    };
  },
});

ROUTER.beforeEach(async to => {
  // CKEditor uses javascript:void(0) in href
  if (to.path.includes("javascript:void")) {
    return false;
  }
  targetRoute.value = {
    path: to.path,
    query: to.query,
  };
  try {
    await getUser();
    await loadSyConfig();
  } catch (e) {

  }
  if (!checkInstancePages(to)) {
    return { name: "root.home" };
  }

  const REDIRECT_ROUTE_NAME = checkRequiredPages(to);

  if (REDIRECT_ROUTE_NAME) {
    return {
      name: REDIRECT_ROUTE_NAME,
    };
  }

  const {
    canAccess,
    toStart,
    query,
  } = await canUserAccess(to);

  if (toStart || canAccess === false) {
    if (to.name === "root.home") {
      return true;
    }
    return {
      name: "root.home",
      query,
    };
  }
  return true;
});

ROUTER.afterEach(() => {
  EventBus.$emit("updateState"); // TODO Remove after switching to AMenu
  try {
    // Tracking des Seitenzugriffs auf Tealium
    // Wrappen in großen try-catch-Block, damit Fehler an dieser
    // Stelle den Programmablauf nicht unterbrechen.
    if (typeof window.utag !== "undefined") {
      window.utag.view({
        dias_userid: window.utag_data.dias_userid,
        page_name: window.location.pathname,
        page_title: window.document.title
      });
    }
  } catch (err) {
    console.error(err);
  }
});

function isAuthenticationValid({
  isAuthenticatedMeta,
  isAuthenticatedUser,
}) {
  if (isAuthenticatedMeta === true) {
    if (!isAuthenticatedUser) {
      return false;
    }
  } else if (isAuthenticatedMeta === false) {
    if (isAuthenticatedUser) {
      return false;
    }
  }
  return true;
}

function canUserAccess(to) {
  const PERMISSIONS = get(to, "meta.permissions") || [];
  const isAuthenticatedMeta = to?.meta?.isAuthenticated;

  if (!isAuthenticationValid({
    isAuthenticatedMeta,
    isAuthenticatedUser: isAuthenticated.value,
  })) {
    return { canAccess: false };
  }

  let authorized = true;

  let passwortAbgelaufenStatus;
  if (user.value &&
    user.value.status &&
    user.value.status.ns_id === PASSWORT_ABGELAUFEN_STATUS_ID) {
    passwortAbgelaufenStatus = true;
  }

  if (PERMISSIONS.length) {
    authorized = checkPermissionsSync({
      perm: PERMISSIONS,
    });
  } else {
    passwortAbgelaufenStatus = false;
  }

  if (!authorized || passwortAbgelaufenStatus) {
    addNotification({
      text: "Sie sind nicht berechtigt diese Seite aufzurufen.",
      type: "warning",
    });
    if (!isAuthenticated.value) {
      // Pfad nur merken, wenn noch nicht angemeldet
      return {
        toStart: true,
        query: {
          next: getNextParameter(to),
        },
      };
    }
    return { toStart: true, };
  }
  return { canAccess: true };
}

function checkInstancePages(to) {
  const INSTANCE_PAGES = get(to, "meta.instance_pages");
  const syConfigsInstancePages = syConfig.value.instance_pages;
  if (INSTANCE_PAGES && (!syConfigsInstancePages || !syConfigsInstancePages[INSTANCE_PAGES])) {
    addNotification({
      text: "Diese Seite konnte nicht gefunden werden.",
      type: "warning",
    });
    return false;
  }
  return true;
}

function checkRequiredPages(to) {
  if (!isAuthenticated.value) {
    return;
  }

  if (user.value.offene_datenschutzfragen?.length) {
    if (to.name === "root.datenschutzeinwilligung" ||
      to.name === "root.datenschutz") {
      return;
    }
    addNotification({
      text: "Bevor Sie die Anwendung weiter verwenden können, müssen Sie die folgenden Fragen beantworten.",
      type: "warning",
    });
    return "root.datenschutzeinwilligung";
  }

  if (user.value.required_nutzerdaten_errors !== null) {
    if (to.name === "root.required_nutzerdaten") {
      return;
    }
    addNotification({
      text: "_ERR_USER_DATA_INCOMPLETE_",
      type: "warning",
    });
    return "root.required_nutzerdaten";
  }
}

function getNextParameter({ fullPath, matched }) {
  if (fullPath === "/" ||
    !matched.length) {
    return;
  }
  return fullPath;
}

export default ROUTER;
