import AButton from "aloha-vue/src/AButton/AButton";

import {
  FilterIbanMixin,
} from "../../../mixins/FiltersMixin";
import UiInputClearButtonMixin from "../UiInputClearButtonMixin";
import UIComponentMixin from "../UIComponentMixin";

import GlobalOptions from "../../../const/GlobalOptions";

// @vue/component
export default {
  name: "UiIban",
  components: {
    AButton,
  },
  mixins: [
    UIComponentMixin,
    FilterIbanMixin,
    UiInputClearButtonMixin,
  ],
  data() {
    return {
      UiInputIcon: GlobalOptions.UiInputIcon,
      modelLocal: undefined,
    };
  },
  computed: {
    getMaxlength() {
      return this.options.maxlength || this.options.max_length || this.options.max_digits;
    },

    getModel() {
      this.mockFunction(this.modelLocal); // Workaround: TODO: Ilia (model ändert sich nicht)
      return this.filterIban(this.model, true);
    },
  },
  methods: {
    onInput($event) {
      let value = $event.target.value;
      value = value.replace(/ /g, "");

      this.onInput_mixin({ value, $event });
    },
  },
};
