import AButton from "aloha-vue/src/AButton/AButton";
import Inputmask from "../../../directives/Inputmask";
import translate from "../../../directives/translate";

import {
  FilterCurrencyMixin,
} from "../../../mixins/FiltersMixin";
import UIComponentMixin from "../UIComponentMixin";
import UiFloatMixin from "./UiFloatMixin";
import UiInputClearButtonMixin from "../UiInputClearButtonMixin";
import UiInputMaxValueMixin from "../UiInputMaxValueMixin";
import UiInputMinValueMixin from "../UiInputMinValueMixin";

import GlobalOptions from "../../../const/GlobalOptions";

import {
  floor,
  indexOf,
  isNil,
  max,
  repeat,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiFloat",
  components: {
    AButton,
  },
  directives: {
    Inputmask,
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    UIComponentMixin,
    UiFloatMixin,
    UiInputClearButtonMixin,
    UiInputMaxValueMixin,
    UiInputMinValueMixin,
  ],
  data() {
    return {
      UiInputIcon: GlobalOptions.UiInputIcon,
      inputIgnoredKeyCodes: [
        8, // Backspace
        46, // Delete
      ]
    };
  },
  computed: {
    getInputmaskOptions() {
      return {
        alias: "decimal",
        radixPoint: ",",
        groupSeparator: this.groupSeparator,
        autoGroup: !this.no_separator_UiFloatMixin,
        digits: this.digits_UiFloatMixin,
        digitsOptional: false,
        placeholder: "0",
        suffix: this.suffix_UiFloatMixin,
        prefix: this.prefix_UiFloatMixin,
        rightAlign: this.inputAlignRight_UiFloatMixin,
      };
    },

    getModel() {
      this.mockFunction(this.modelLocal); // Workaround: TODO: Ilia für options.maxValue(model ändert sich nicht)
      const SUFFIX = this.suffixOption_UiFloatMixin;
      return `${ this.prefix_UiFloatMixin }${ this.filterCurrency(this.model, SUFFIX, this.digits_UiFloatMixin, !this.no_separator_UiFloatMixin) }`;
    },

    getMaxlength() {
      const NUMBER_LENGTH = this.options.maxlength || this.options.max_length || this.options.max_digits;
      let len = NUMBER_LENGTH;
      if (this.suffix_UiFloatMixin) {
        // Zeichen für Suffix einrechnen
        len += size(this.suffix_UiFloatMixin) + 1;
      }
      if (this.prefix_UiFloatMixin) {
        // Zeichen für Prefix einrechnen
        len += size(this.prefix_UiFloatMixin) + 1;
      }
      if (this.digits_UiFloatMixin > 0) {
        // Zeichen für Nachkommastellen-Trenner einrechnen
        len++;
      }
      // Zeichen für Tausender-Trenner einrechnen
      if (!this.no_separator_UiFloatMixin) {
        len += floor(max([NUMBER_LENGTH - this.digits_UiFloatMixin - 1, 0]) / 3);
      }
      return len;
    },

    groupSeparator() {
      return this.no_separator_UiFloatMixin ? "" : ".";
    },

    translateForInput() {
      if (this.options.placeholder) {
        return {
          placeholder: this.options.placeholder,
          extra: this.options.extra,
        };
      }
      return {};
    },
  },
  methods: {
    onKeyUp($event) {
      if (indexOf(this.inputIgnoredKeyCodes, $event.keyCode) !== -1) {
        this.onInput($event);
      }
    },
    onInput($event) {
      let value = $event.target.value;
      const inputIsNegativeZero = value == "-0," + repeat("0", this.digits_UiFloatMixin);
      const modelIsZero = isNil(this.model) || this.model == 0;

      if (this.suffix_UiFloatMixin) {
        value = value.split(this.suffix_UiFloatMixin).join("");
      }
      if (this.prefix_UiFloatMixin) {
        value = value.split(this.prefix_UiFloatMixin).join("");
      }
      value = +value.replace(/\./g, "").replace(/,/g, ".");
      value = this.checkMaxValue({ value });
      value = this.checkMinValue({ value });
      if (inputIsNegativeZero && value === 0 && modelIsZero) {
        // Workaround zum Eingeben negativer Werte.
        // Problem: Wenn man die Zahleneingabe mit einem Minus beginnt
        // wenn die Zahl noch leer oder Null ist, kommt inputmask
        // durcheinander wenn wir die Modeländerungen propagieren und
        // das Model mit einer positiven Null upgedated wird; das
        // eingegebene Minus erscheint nicht im Inputfeld.
        // Lösung: Wenn das Model eh Null oder leer ist, brauchen wir die
        // Änderung zur negativen Null nicht propagieren und können
        // hier sofort abbrechen.
        return;
      }

      this.onInput_mixin({ value, $event });
      this.modelLocal = !this.modelLocal;
    },
  },
};
