import {
  isPlainObject,
  isUndefined,
} from "lodash-es";
import {
  gettext,
  replaceText,
  isTranslatePlaceholder,
} from "../functions/utils";
import {
  EventBus,
} from "../functions/event-bus";
import PermissionAPI from "../compositionAPI/PermissionAPI";

function setTranslate({ el, value, statusShow }) {
  if (!isPlainObject(value)) {
    return;
  }
  const NOT_TRANSLATE = isUndefined(statusShow) ? window.statusShowPlaceholdersTranslate : statusShow;
  const TEXT = value.text;
  const HTML = value.html;
  const TITLE = value.title;
  const PLACEHOLDER = value.placeholder;
  const ARIA_LABEL = value["aria-label"];

  if (TEXT) {
    const NOT_TRANSLATE_TEXT = NOT_TRANSLATE || !isTranslatePlaceholder(TEXT);
    el.textContent = NOT_TRANSLATE_TEXT ? TEXT : replaceText({ text: gettext(TEXT), object: value.extra });
    if (!NOT_TRANSLATE_TEXT) {
      el.setAttribute("data-translate-text", TEXT);
    }
  }
  if (HTML) {
    const NOT_TRANSLATE_HTML = NOT_TRANSLATE || !isTranslatePlaceholder(HTML);
    el.innerHTML = NOT_TRANSLATE_HTML ? HTML : replaceText({ text: gettext(HTML), object: value.extra });
    if (!NOT_TRANSLATE_HTML) {
      el.setAttribute("data-translate-html", HTML);
    }
  }
  if (TITLE) {
    const NOT_TRANSLATE_TITLE = NOT_TRANSLATE || !isTranslatePlaceholder(TITLE);
    el.setAttribute("title", NOT_TRANSLATE_TITLE ? TITLE : replaceText({ text: gettext(TITLE), object: value.extra }));
    if (!NOT_TRANSLATE_TITLE) {
      el.setAttribute(`data-translate-title`, TITLE);
    }
  }
  if (PLACEHOLDER) {
    const NOT_TRANSLATE_PLACEHOLDER = NOT_TRANSLATE || !isTranslatePlaceholder(PLACEHOLDER);
    el.setAttribute("placeholder", NOT_TRANSLATE_PLACEHOLDER ? PLACEHOLDER : replaceText({ text: gettext(PLACEHOLDER), object: value.extra }));
    if (!NOT_TRANSLATE_PLACEHOLDER) {
      el.setAttribute(`data-translate-placeholder`, PLACEHOLDER);
    }
  }
  if (ARIA_LABEL) {
    const NOT_TRANSLATE_ARIA_LABEL = NOT_TRANSLATE || !isTranslatePlaceholder(ARIA_LABEL);
    el.setAttribute("aria-label", NOT_TRANSLATE_ARIA_LABEL ? ARIA_LABEL : replaceText({ text: gettext(ARIA_LABEL), object: value.extra }));
    if (!NOT_TRANSLATE_ARIA_LABEL) {
      el.setAttribute(`data-translate-aria-label`, ARIA_LABEL);
    }
  }
}


export default {
  beforeMount(el, binding) {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    EventBus.$on("showPlaceholdersTranslate", ({ statusShow }) => setTranslate({ el, value: binding.value, statusShow }));
    setTranslate({ el, value: binding.value });
    el.addEventListener("click", event => {
      if (event.ctrlKey && event.shiftKey) {
        if (!checkPermissionsSync("i18n.view.inline") || !checkPermissionsSync("kataloge.admin.view")) {
          return;
        }
        event.stopPropagation();
        event.preventDefault();
        EventBus.$emit("setTranslate", ({ value: binding.value }));
      }
    });
  },
  updated(el, binding) {
    setTranslate({ el, value: binding.value });
  },
};
