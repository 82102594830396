import {
  ref,
} from "vue";

import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import ASpinner from "aloha-vue/src/ASpinner/ASpinner";
import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import Permission from "../../global/components/Permission/Permission.vue";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";
import TheReleaseNotesModal from "../TheReleaseNotesModal/TheReleaseNotesModal.vue";

import PermissionAPI from "../../global/compositionAPI/PermissionAPI";
import ActionsAPI from "./compositionAPI/ActionsAPI";

// @vue/component
export default {
  name: "TheMenuUser",
  components: {
    ADropdown,
    AngularLink,
    ASpinner,
    Permission,
    PuxIcon,
    PuxTranslate,
    TheReleaseNotesModal,
  },
  setup() {
    const {
      fullName,
      me,
    } = PermissionAPI();

    const idForVersionButton = "btn_versions";
    const selectorClose = `#${ idForVersionButton }`;

    const isModalOpen = ref(false);
    const openModal = () => {
      isModalOpen.value = true;
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    const {
      dropdownActions,
    } = ActionsAPI({
      idForVersionButton,
      openModal,
    });

    return {
      closeModal,
      dropdownActions,
      fullName,
      idForVersionButton,
      isModalOpen,
      me,
      openModal,
      selectorClose,
    };
  },
};
