import {
  computed,
  toRef,
} from "vue";

export default function TheNavbarStylesAPI({
  props,
}) {
  const partMarked = toRef(props, "partMarked");
  const markedPartClassLeft = computed(() => {
    return partMarked.value === "left" ? "outline-red-3" : "";
  });
  const markedPartClassCenter = computed(() => {
    return partMarked.value === "center" ? "outline-red-3" : "";
  });
  const markedPartClassRight = computed(() => {
    return partMarked.value === "right" ? "outline-red-3" : "";
  });

  return {
    markedPartClassCenter,
    markedPartClassLeft,
    markedPartClassRight,
  };
}
