import PermissionMixin from "../../mixins/PermissionMixin";
import {
  EventBus,
} from "../../functions/event-bus";

// @vue/component
export default {
  name: "Permission",
  mixins: [
    PermissionMixin,
  ],
  inheritAttrs: false,
  props: {
    permission: {
      type: [String, Array],
      required: false,
      default: undefined,
      info: "Berechtigung oder Berechtigungen",
    },
    src: {
      type: Array,
      required: false,
      default: undefined,
      info: "Liste, wo man Berechtigungen prüfen soll",
    },
    missing: {
      type: Boolean,
      required: false,
      info: "Child ist angezeigt, wenn Berechtigungen fehlen",
    },
  },
  data() {
    return {
      statusShow: undefined,
    };
  },
  watch: {
    permission: {
      handler() {
        this._checkPermission();
      },
      deep: true
    },

    src: {
      handler() {
        this._checkPermission();
      },
      deep: true
    },

    missing() {
      this._checkPermission();
    },
  },
  created() {
    this._checkPermission();
    this.setEventBus();
  },
  beforeUnmount() {
    this.deleteEventBus();
  },
  methods: {
    setEventBus() {
      EventBus.$on("updateProfile", this._checkPermission);
    },

    deleteEventBus() {
      EventBus.$off("updateProfile", this._checkPermission);
    },

    _checkPermission() {
      let statusShow = this.checkPermissionsSync({ perm: this.permission, permArray: this.src });
      if (this.missing) {
        statusShow = !statusShow;
      }
      this.statusShow = statusShow;
    },
  },
  render() {
    return this.statusShow && this.$slots.default && this.$slots.default();
  },
};
