import {
  computed,
} from "vue";

import {
  isFooterFixed,
} from "../../../App/compositionAPI/LayoutAPI";

export default function MainAPI() {
  const getPanelFooterClass = computed(() => {
    return isFooterFixed.value ? "panel_footer_fixed" : "";
  });

  return {
    getPanelFooterClass,
  };
}
