let inc = new Date().getTime();

// @vue/component
export default {
  name: "PuxCkeditor",
  props: {
    name: {
      type: String,
      default: () => `editor-${ ++inc }`
    },
    modelValue: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default: () => `editor-${ inc }`
    },
    types: {
      type: String,
      default: () => `classic`
    },
    config: {
      type: Object,
      default: () => {}
    },
    instanceReadyCallback: {
      type: Function,
      default: undefined,
    },
    readOnlyMode: {
      type: Boolean,
      default: () => false
    }
  },
  emits: [
    "update:modelValue",
    "blur",
    "focus",
    "contentDom",
    "dialogDefinition",
    "fileUploadRequest",
    "fileUploadResponse",
  ],
  data() {
    return {
      instanceValue: ""
    };
  },
  computed: {
    instance() {
      return CKEDITOR.instances[this.id];
    }
  },
  watch: {
    modelValue(val) {
      try {
        if (this.instance) {
          this.update(val);
        }
      } catch (e) {}
    },

    readOnlyMode(val) {
      this.instance.setReadOnly(val);
    },
  },
  mounted() {
    this.create();
  },
  beforeUnmount() {
    this.destroy();
  },
  methods: {
    create() {
      if (typeof CKEDITOR === "undefined") {
        console.log("CKEDITOR is missing (http://ckeditor.com/)");
      } else {
        if (this.types === "inline") {
          CKEDITOR.inline(this.id, this.config);
        } else {
          CKEDITOR.replace(this.id, this.config);
        }

        this.instance.setData(this.modelValue);

        this.instance.on("instanceReady", () => {
          this.instance.setData(this.modelValue);
        });

        // Ckeditor change event
        this.instance.on("change", this.onChange);

        // Ckeditor mode html or source
        this.instance.on("mode", this.onMode);

        // Ckeditor blur event
        this.instance.on("blur", evt => {
          this.$emit("blur", evt);
        });

        // Ckeditor focus event
        this.instance.on("focus", evt => {
          this.$emit("focus", evt);
        });

        // Ckeditor contentDom event
        this.instance.on("contentDom", evt => {
          this.$emit("contentDom", evt);
        });

        // Ckeditor dialog definition event
        CKEDITOR.on("dialogDefinition", evt => {
          this.$emit("dialogDefinition", evt);
        });

        // Ckeditor file upload request event
        this.instance.on("fileUploadRequest", evt => {
          this.$emit("fileUploadRequest", evt);
        });

        // Ckditor file upload response event
        this.instance.on("fileUploadResponse", evt => {
          setTimeout(() => {
            this.onChange();
          }, 0);
          this.$emit("fileUploadResponse", evt);
        });

        // Listen for instanceReady event
        if (typeof this.instanceReadyCallback !== "undefined") {
          this.instance.on("instanceReady", this.instanceReadyCallback);
        }
      }
    },
    update(val) {
      if (this.instanceValue !== val) {
        this.instance.setData(val, { internal: false });
      }
    },
    destroy() {
      try {
        const editor = window.CKEDITOR;
        if (editor.instances) {
          for (const instance in editor.instances) {
            instance.destroy();
          }
        }
      } catch (e) {}
    },
    onMode() {
      if (this.instance.mode === "source") {
        const editable = this.instance.editable();
        editable.attachListener(editable, "input", () => {
          this.onChange();
        });
      }
    },
    onChange() {
      const html = this.instance.getData();
      if (html !== this.modelValue) {
        this.$emit("update:modelValue", html);
        this.instanceValue = html;
      }
    }
  }
};
