import UiBelegeExtraFieldFinanzierung from "./ui/UiBelegeExtraFieldFinanzierung/UiBelegeExtraFieldFinanzierung.vue";
import UiBelegeExtraFieldKosten from "./ui/UiBelegeExtraFieldKosten/UiBelegeExtraFieldKosten.vue";
import UiBelegeField from "./ui/UiBelegeField/UiBelegeField.vue";
import UiBelegpruefartFelder from "./ui/UiBelegpruefartFelder/UiBelegpruefartFelder.vue";
import UiZuschussPeriodenConfig from "./ui/UiZuschussPeriodenConfig/UiZuschussPeriodenConfig.vue";

import UiBelegeFieldReadOnly from "./ui/UiBelegeField/UiBelegeFieldReadOnly/UiBelegeFieldReadOnly.vue";
import UiBelegpruefartFelderReadOnly from "./ui/UiBelegpruefartFelder/UiBelegpruefartFelderReadOnly/UiBelegpruefartFelderReadOnly.vue";
import UiZuschussPeriodenConfigReadOnly from "./ui/UiZuschussPeriodenConfig/UiZuschussPeriodenConfigReadOnly/UiZuschussPeriodenConfigReadOnly.vue";

export default {
  UiBelegeExtraFieldFinanzierung,
  UiBelegeExtraFieldKosten,
  UiBelegeField,
  UiBelegpruefartFelder,
  UiZuschussPeriodenConfig,
};

export const READ_ONLY = {
  UiBelegeFieldReadOnly,
  UiBelegpruefartFelderReadOnly,
  UiZuschussPeriodenConfigReadOnly,
};

export const MAPPING = {
  belege_extra_field_kosten: "ui-belege-extra-field-kosten",
  belege_extra_field_finanz: "ui-belege-extra-field-finanzierung",
  belege_field: "ui-belege-field",
  belegpruefart_felder: "ui-belegpruefart-felder",
  perioden_zuschuss_config: "ui-zuschuss-perioden-config",
};

export const MAPPING_READONLY = {
  belege_extra_field_kosten: "ui-dynamische-form-definition-read-only",
  belege_extra_field_finanz: "ui-dynamische-form-definition-read-only",
  belege_field: "ui-belege-field-read-only",
  belegpruefart_felder: "ui-belegpruefart-felder-read-only",
  perioden_zuschuss_config: "ui-zuschuss-perioden-config-read-only",
};

export const MODEL = {
  // belege_extra_field_kosten: () => ({ __new__: true }),
  // belege_extra_field_finanz: () => ({ __new__: true }),
};

export const EXTRA_OPTIONS_FOR_TYPE = {
};
