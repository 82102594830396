import {
  cloneDeep,
  forEach,
  orderBy,
} from "lodash-es";

export default function TheMenuOrderByAPI() {
  function orderByMenu({ menu }) {
    return orderByMenuLevel({ level: cloneDeep(menu) });
  }

  function orderByMenuLevel({ level }) {
    forEach(level, item => {
      if (item.children && item.children.length) {
        item.children = orderByMenuLevel({ level: item.children });
      }
    });
    return orderBy(level, ["priority"]);
  }

  return {
    orderByMenu,
  };
}
