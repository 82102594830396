import translate from "../../../../directives/translate";

import UiFloatMixin from "../UiFloatMixin";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";
import {
  FilterCurrencyMixin,
} from "../../../../mixins/FiltersMixin";

// @vue/component
export default {
  name: "UiFloatReadOnly",
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    UiFloatMixin,
    UIReadOnlyMixin,
  ],
  computed: {
    modelLocal() {
      const SUFFIX = this.suffixOption_UiFloatMixin;
      return `${ this.prefix_UiFloatMixin }${ this.filterCurrency(this.model, SUFFIX, this.digits_UiFloatMixin) }`;
    },
  },
};
