import translate from "../../directives/translate";
import AButton from "aloha-vue/src/AButton/AButton";
import PuxLabel from "./PuxLabel/PuxLabel.vue";
import Alert from "../Alert/Alert.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";
import PuxTooltip from "../PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";
import COMPONENT_TYPE_MAPPING from "./FormElementTypeMapping";
import UiComponents from "../../../views/Geschaeftsregel/Module/UiComponents";
import KF_UiComponents from "../../../views/KF/UiComponents";
import SITZUNG_UiComponents from "../../../views/Sitzung/SitzungModule/Module/UiComponents";
import UiInput from "../ui/UiInput/UiInput.vue";
import UiInputReadOnly from "../ui/UiInput/UiInputReadOnly/UiInputReadOnly.vue";
import UiTextarea from "../ui/UiTextarea/UiTextarea.vue";
import UiDatepicker from "../ui/UiDatepicker/UiDatepicker.vue";
import UiDatepickerRange from "../ui/UiDatepickerRange/UiDatepickerRange.vue";
import UiSelect from "../ui/UiSelect/UiSelect.vue";
import UiSelectOrdered from "../ui/UiSelect/UiSelectOrdered/UiSelectOrdered.vue";
import UiCkeditor from "../ui/UiCkeditor/UiCkeditor.vue";
import UiCheckbox from "../ui/UiCheckbox/UiCheckbox.vue";
import UiRadiobutton from "../ui/UiRadiobutton/UiRadiobutton.vue";
import UiBoolean from "../ui/UiBoolean/UiBoolean.vue";
import UiDocument from "../ui/UiDocument/UiDocument.vue";
import UiReadOnly from "../ui/UiReadOnly/UiReadOnly.vue";
import UiNumberRange from "../ui/UiNumberRange/UiNumberRange.vue";
import UiTemplate from "../ui/UiTemplate/UiTemplate.vue";
import UiRange from "../ui/UiRange/UiRange.vue";
import UiJson from "../ui/UiJson/UiJson.vue";
import UiJsonEditor from "../ui/UiJsonEditor/UiJsonEditor.vue";
import UiCoordinates from "../ui/UiCoordinates/UiCoordinates.vue";
import UiFloat from "../ui/UiFloat/UiFloat.vue";
import UiCurrency from "../ui/UiCurrency/UiCurrency.vue";
import UiDayMonth from "../ui/UiDayMonth/UiDayMonth.vue";
import UiIban from "../ui/UiIban/UiIban.vue";
import UiDynamischeFeldwertliste from "../ui/UiDynamischeFeldwertliste/UiDynamischeFeldwertliste.vue";
import UiDynamischeFormDefinition from "../ui/UiDynamischeFormDefinition/UiDynamischeFormDefinition.vue";
import UiAutomatic from "../ui/UiAutomatic/UiAutomatic.vue";
import UiRandomStringParts from "../ui/UiRandomStringParts/UiRandomStringParts.vue";
import UiRating from "../ui/UiRating/UiRating.vue";
import UiStrMapping from "../ui/UiStrMapping/UiStrMapping.vue";
import UiOneCheckbox from "../ui/UiOneCheckbox/UiOneCheckbox.vue";
import UiTextbaustein from "../ui/UiTextbaustein/UiTextbaustein.vue";
import UiValidatedJson from "../ui/UiValidatedJson/UiValidatedJson.vue";
import UiTree from "../ui/UiTree/UiTree.vue";
import UiTinymce from "../ui/UiTinymce/UiTinymce.vue";

import {
  sanitize,
} from "aloha-vue/src/utils/utils";

import {
  deburr,
  has,
  isString,
  isNumber,
  isArray,
  toString,
  isFunction,
  size,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "FormElement",
  directives: {
    translate,
  },
  components: {
    AButton,
    Alert,
    PuxLabel,
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
    UiInput,
    UiInputReadOnly,
    UiDatepicker,
    UiDatepickerRange,
    UiSelect,
    UiSelectOrdered,
    UiCkeditor,
    UiCheckbox,
    UiRadiobutton,
    UiBoolean,
    UiTextarea,
    UiDocument,
    UiReadOnly,
    UiNumberRange,
    UiTemplate,
    UiRange,
    UiJson,
    UiJsonEditor,
    UiCoordinates,
    UiFloat,
    UiCurrency,
    UiDayMonth,
    UiIban,
    UiAutomatic,
    UiRandomStringParts,
    UiRating,
    UiStrMapping,
    UiOneCheckbox,
    UiTextbaustein,
    UiDynamischeFeldwertliste,
    UiDynamischeFormDefinition,
    UiValidatedJson,
    UiTinymce,
    UiTree,
    ...UiComponents,
    ...KF_UiComponents,
    ...SITZUNG_UiComponents,
  },
  props: {
    errors: {
      type: [Array, Object],
      default: () => [],
    },
    modelValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    data: { // UiSelect
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
      validator(value) {
        return value.type;
      }
    },
    idPrefix: {
      type: String,
      default: "",
    },
    focus: {
      type: Function,
      default: () => {},
    },
    blur: {
      type: Function,
      default: () => {},
    },
    input: {
      type: Function,
      default: () => {},
    },
    change: {
      type: Function,
      default: () => {},
    },
    loadListCallback: {
      type: Function,
      default: undefined,
    },
    dependencyValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    view: {
      type: String,
      required: false,
      default: undefined,
    },
    extraStatic: {
      type: Object,
      required: false,
      default: undefined,
    },
    updateErrors: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "update:modelValue",
    "change",
  ],
  data() {
    return {
      COMPONENT_TYPE_MAPPING: COMPONENT_TYPE_MAPPING,
      status: {
        loading: true,
        focus: undefined,
        collapsed: undefined,
      },
      statusInfo: false,
    };
  },
  computed: {
    getFormClasses() {
      let form_vertical_filled;
      if (((isString(this.modelValue) || isNumber(this.modelValue)) && toString(this.modelValue).length) ||
        (has(this.options, "emptyValue") && this.modelValue === this.options.emptyValue)) {
        form_vertical_filled = true;
      } else if (isArray(this.modelValue) && this.modelValue.length) {
        form_vertical_filled = true;
      }
      return {
        form_vertical: this.viewLocal === "v",
        form_horizontal: this.viewLocal === "h",
        form_vertical_filled: form_vertical_filled,
        form_vertical_focus: this.status.focus,
        form_vertical_not_focus: this.status.focus === false,
      };
    },

    getClassName() {
      const LABEL = this.options.label || "";
      const LABEL_WITHOUT_UMLAUT = deburr(LABEL);
      const LABEL_WITH_ONLY_SYMBOLS = LABEL_WITHOUT_UMLAUT.replace(/\W/g, "");
      return `test_${ LABEL_WITH_ONLY_SYMBOLS }`;
    },

    htmlFor() {
      if (this.options.htmlId) {
        return this.options.htmlId;
      }
      return `${ this.idPrefix }${ this.options.attrId || this.options.key || this.options.id }`;
    },

    isErrorsSize() {
      return !this.options.hideErrors && !!size(this.errors);
    },

    classesHorizontal() {
      if (this.options.classesHorizontal) {
        return this.options.classesHorizontal;
      }
      return [
        "a_text_right col-sm-3 a_mb_0",
        "col-sm-9",
        "offset-sm-3",
      ];
    },

    downLabelTranslateOptions() {
      return {
        text: this.downLabel,
        extra: {
          characters: this.characters,
        },
      };
    },

    downLabel() {
      if (this.charactersLeft < 0) {
        return "_LBL_ZEICHEN_ZU_LANG_{{characters}}_";
      }
      return "_LBL_NOCH_ZEICHEN_{{characters}}_";
    },

    characters() {
      if (this.charactersLeft < 0) {
        return -1 * this.charactersLeft;
      }
      return this.charactersLeft;
    },

    charactersLeft() {
      const model = isNil(this.modelValue) ? "" : this.modelValue;
      if (this.options.type !== "richtext") {
        return this.options.max - model.length;
      }
      const div = document.createElement("div");
      div.innerHTML = sanitize(model);
      const text = div.textContent || div.innerText || "";
      return this.options.max - text.replace(/[\t\n\r]/g, "").length;
    },

    prefixLabel() {
      if (this.options.min) {
        return "_LBL_MIN_MAX_ZEICHEN_{{min}}_{{max}}_";
      }
      return "_LBL_MAX_ZEICHEN_{{max}}_";
    },

    prefixLabelTranslateOptions() {
      return {
        text: this.prefixLabel,
        extra: {
          min: this.options.min,
          max: this.options.max,
        },
      };
    },

    statusShowMaxZeichen() {
      if (this.options.max &&
        (this.options.type === "text" ||
          this.options.type === "richtext")) {
        return true;
      }
      return false;
    },

    statusErrorArray() {
      return isArray(this.errors);
    },

    statusErrorString() {
      return isString(this.errors);
    },

    statusLabelShow() {
      const TYPES_WITHOUT_LABELS = {
        one_checkbox: true,
        tree: true,
      };
      return !TYPES_WITHOUT_LABELS[this.options.type] && !this.options.hideLabel;
    },

    addonBackId() {
      return `${ this.htmlFor }_addon_back`;
    },

    errorsBlockId() {
      return `${ this.htmlFor }_errors`;
    },

    helpTextId() {
      return `${ this.htmlFor }_help_text`;
    },

    tooltipId() {
      return `${ this.htmlFor }_tooltip`;
    },

    buttonInfoId() {
      return `${ this.htmlFor }_info_button`;
    },

    buttonInfoCloseId() {
      return `${ this.htmlFor }_info_close`;
    },

    blockInfoId() {
      return `${ this.htmlFor }_info_block`;
    },

    buttonInfoTooltip() {
      return this.statusInfo ?
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_AUS_" :
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_EIN_";
    },

    idForLabel() {
      return `${ this.htmlFor }_label`;
    },

    viewLocal() {
      return this.view || this.options.view;
    },
  },
  created() {
    this.setStatusInfo();
    this.initOptions();
  },
  methods: {
    setStatusInfo() {
      if (this.options.infoText && this.options.infoTextActiveDefault) {
        this.statusInfo = true;
      }
    },

    initOptions() {
      this.status.collapsed = this.options.show;
      this.status.loading = false;
    },

    onFocus(arg) {
      this.status.focus = true;
      this.focus(arg);
    },

    onBlur(arg) {
      this.status.focus = false;
      this.blur(arg);
    },

    onInput(arg) {
      this.$emit("update:modelValue", arg.model);
      this.input(arg);
      this.changeLocal(arg);
    },

    toggleCollapse() {
      this.status.collapsed = !this.status.collapsed;
    },

    changeLocal(arg) {
      if (isFunction(this.options.change)) {
        this.options.change(arg);
      }
    },

    toggleInfo() {
      this.statusInfo = !this.statusInfo;
    },

    clickOnLabel() {
      if (this.$refs.child &&
        isFunction(this.$refs.child.clickOnLabelFromParent)) {
        this.$refs.child.clickOnLabelFromParent();
      }
    },
  },
};
