import PuxCkeditor from "../PuxCkeditor/PuxCkeditor.vue";

import UIComponentMixin from "../UIComponentMixin";
import translate from "../../../directives/translate";
import {
  EventBus,
} from "../../../functions/event-bus";
import {
  size
} from "lodash-es";

// @vue/component
export default {
  name: "UiCkeditor",
  components: {
    PuxCkeditor,
  },
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
  ],
  data() {
    return {
      config: {
        forcePasteAsPlainText: true,
        disableNativeSpellChecker: false,
        // extraPlugins: "abbr,menubutton,language,simplelinkDIAS,divarea,autogrow",
        extraPlugins: "abbr,language,divarea,autogrow",
        removePlugins: "scayt,contextmenu,tabletools,tableselection",
        language_list: ["en:Englisch", "fr:Französisch", "es:Spanisch"],
        toolbarGroups: [
          {
            name: "basicstyles",
            groups: ["basicstyles", "cleanup"],
          },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
          },
          {
            name: "links",
            groups: ["links"],
          },
          {
            name: "insert",
            groups: ["insert"],
          },
          {
            name: "clipboard",
            groups: ["clipboard", "undo"],
          },
        ],
        removeButtons: "Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript,Underline,RemoveFormat,Blockquote,Table,HorizontalRule,SpecialChar,PasteText,PasteFromWord,Image,",
        height: 200,
        title: false,
        startupFocus: this.options.startupFocus,
      },
      minHeight: "200px",
      destroyed: undefined,
      initialized: undefined,
      timer: undefined,
      dragHtml: undefined,
      dragHtmlLocal: undefined,
    };
  },
  computed: {
    internalValue() {
      // vue-ckeditor setzt sonst null/undefined zurück auf den initialen Wert, daher leerer String
      return this.model || "";
    },
  },
  created() {
    this.setConfig();
  },
  mounted() {
    this.$nextTick(() => {
      this.initialized = true;
    });
    this.setEvents();
  },
  beforeUnmount() {
    this.destroy();
    EventBus.$off("dragStartCKEditor", this.setDragData);
  },
  methods: {
    setConfig() {
      if (!this.options.dnd) {
        return;
      }
      this.config.removeButtons = this.config.removeButtons.replace("Image,", "");
    },

    setEvents() {
      if (!this.options.dnd) {
        return;
      }
      this.timer = setTimeout(() => {
        if (this.initialized && this.$refs.vueCkeditor) {
          this.$refs.vueCkeditor.instance.on("drop", this.dropInEditor);
          this.$refs.vueCkeditor.instance.on("dragstart", this.dragstartInEditor);
          clearTimeout(this.timer);
        }
      }, 500);
      EventBus.$on("dragStartCKEditor", this.setDragHtml);
    },

    dragstartInEditor(evt) {
      this.dragHtml = evt.data.target.$.outerHTML;
      this.dragHtmlLocal = evt.data.target.$;
    },

    dropInEditor(evt) {
      if (!this.dragHtml) {
        this.$refs.vueCkeditor.onChange();
        return;
      }

      const TAG_P = document.createElement("p");

      TAG_P.innerHTML = this.dragHtml;

      evt.data.dropRange.endContainer.$.parentElement.appendChild(TAG_P);
      if (this.dragHtmlLocal && this.dragHtmlLocal.parentNode) {
        this.dragHtmlLocal.parentNode.removeChild(this.dragHtmlLocal);
        this.dragHtmlLocal = undefined;
      }
      this.$refs.vueCkeditor.onChange();
      this.dragHtml = undefined;
    },

    setDragHtml({ html }) {
      this.dragHtml = html;
    },

    onInput(arg, $event) {
      if (size(arg) === 0) {
        arg = null;
      }
      this.input({
        currentModel: arg,
        model: arg,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        currentModel: arg,
        model: arg,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    onBlur($event) {
      this.blur({
        id: this.options.id,
        model: this.model,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    onFocus($event) {
      this.focus({
        id: this.options.id,
        model: this.model,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    destroy() {
      if (!this.destroyed) {
        const instance = CKEDITOR.instances[this.getId];
        if (instance) {
          instance.focusManager.blur(true);
          instance.destroy();
          this.destroyed = true;
        }
      }
    },
  },
};
