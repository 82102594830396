import boolean from "../filters/boolean";
import currency from "../filters/currency";
import date from "../filters/date";
import DefaultForEmpty from "../filters/DefaultForEmpty";
import Email from "../filters/Email";
import FileSize from "../filters/FileSize";
import FromNow from "../filters/FromNow";
import iban from "../filters/iban";
import json from "../filters/json";
import KeyValue from "../filters/KeyValue";
import limitTo from "../filters/limitTo";
import Link from "../filters/Link";
import list from "../filters/list";
import searchHighlight from "../filters/searchHighlight";
import spaced from "../filters/spaced";

export const FilterBooleanMixin = {
  methods: {
    filterBoolean(value, { trueValue = "Ja", falseValue = "Nein", isNilUsed = true } = {}) {
      return boolean(value, { trueValue, falseValue, isNilUsed });
    },
  },
};

export const FilterCurrencyMixin = {
  methods: {
    filterCurrency(value, param = "€", digits = 2, digitGrouping = true) {
      return currency(value, param, digits, digitGrouping);
    },
  },
};

export const FilterDateMixin = {
  methods: {
    filterDate(value, param) {
      return date(value, param);
    },
  },
};

export const FilterDefaultForEmptyMixin = {
  methods: {
    filterDefaultForEmpty(value, emptyValue = "-") {
      return DefaultForEmpty(value, emptyValue);
    },
  },
};

export const FilterEmailMixin = {
  methods: {
    filterEmail(value) {
      return Email(value);
    },
  },
};

export const FilterFileSizeMixin = {
  methods: {
    filterFileSize(value, units) {
      return FileSize(value, units);
    },
  },
};

export const FilterFromNowMixin = {
  methods: {
    filterFromNow(value, format) {
      return FromNow(value, format);
    },
  },
};

export const FilterIbanMixin = {
  methods: {
    filterIban(value, plain) {
      return iban(value, plain);
    },
  },
};

export const FilterJsonMixin = {
  methods: {
    filterJson(value, parameter = 2) {
      return json(value, parameter);
    },
  },
};

export const FilterKeyValueMixin = {
  methods: {
    filterKeyValue(value) {
      return KeyValue(value);
    },
  },
};

export const FilterLimitToMixin = {
  methods: {
    filterLimitTo(value, param = 30) {
      return limitTo(value, param);
    },
  },
};

export const FilterLinkMixin = {
  methods: {
    filterLink(value, param = "http://", target = "_blank") {
      return Link(value, param, target);
    },
  },
};

export const FilterListMixin = {
  methods: {
    filterList(value, isHtml = true, listClass = "list-unstyled") {
      return list(value, isHtml, listClass);
    },
  },
};

export const FilterSearchHighlightMixin = {
  methods: {
    filterSearchHighlight(value, searchModel = "") {
      return searchHighlight(value, searchModel);
    },
  },
};

export const FilterSpacedMixin = {
  methods: {
    filterSpaced(value, statusNotHtml) {
      return spaced(value, statusNotHtml);
    },
  },
};

export default {
  mixins: [
    FilterBooleanMixin,
    FilterCurrencyMixin,
    FilterDateMixin,
    FilterDefaultForEmptyMixin,
    FilterEmailMixin,
    FilterFileSizeMixin,
    FilterFromNowMixin,
    FilterIbanMixin,
    FilterJsonMixin,
    FilterKeyValueMixin,
    FilterLimitToMixin,
    FilterLinkMixin,
    FilterSearchHighlightMixin,
    FilterSpacedMixin,
  ],
};
