import {
  computed,
  toRef,
} from "vue";

import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../ShowMore/ShowMore.vue";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import SyConfigAPI from "../../../../compositionAPI/SyConfigAPI";
import UiReadOnlyAPI from "../../compositionAPI/UiReadOnlyAPI";

// @vue/component
export default {
  name: "UiCkeditorReadOnly",
  components: {
    PuxTranslate,
    ShowMore,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  setup(props) {
    const options = toRef(props, "options");

    const {
      getSyConfigsValue,
    } = SyConfigAPI();
    const config = computed(() => {
      return getSyConfigsValue("richtext", {});
    });

    const maxHeightReadOnly = computed(() => {
      return config.value.max_height_readonly;
    });

    const isShowMoreVisible = computed(() => {
      return !options.value.hideShowMore && maxHeightReadOnly.value;
    });

    const {
      emptyText,
      isModelDefined,
    } = UiReadOnlyAPI(props);

    return {
      emptyText,
      isModelDefined,
      isShowMoreVisible,
      maxHeightReadOnly,
    };
  },
};
