import UIReadOnlyMixin from "../../UIReadOnlyMixin";
import {
  FilterDateMixin,
} from "../../../../mixins/FiltersMixin";

// @vue/component
export default {
  name: "UiDatepickerReadOnly",
  mixins: [
    FilterDateMixin,
    UIReadOnlyMixin,
  ],
  data() {
    return {
      format: {
        date: "DD.MM.YYYY",
        datetime: "DD.MM.YYYY HH:mm",
        time: "HH:mm",
      },
    };
  },
  computed: {
    getFormat() {
      return this.options.format || this.format[this.options.type];
    },

    getModel() {
      return this.filterDate(this.model, this.getFormat);
    },
  },
};
