import AButton from "aloha-vue/src/AButton/AButton";

import UiRatingMixin from "../UiRatingMixin";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiRatingReadOnly",
  components: {
    AButton,
  },
  mixins: [
    UiRatingMixin,
    UIReadOnlyMixin,
  ],
};
