import UiFloatReadOnly from "../../UiFloat/UiFloatReadOnly/UiFloatReadOnly";

// @vue/component
export default {
  name: "UiCurrencyReadOnly",
  mixins: [
    UiFloatReadOnly
  ],
  computed: {
    suffixOption_UiFloatMixin() {
      return this.options.suffix || "€";
    },
  },
};
