import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

import Teleport from "../../directives/Teleport";

import {
  EventBus,
} from "../../functions/event-bus";
import {
  createPopper,
} from "popperjs";

export default {
  components: {
    PuxTranslate,
  },
  directives: {
    Teleport,
  },
  data() {
    return {
      statusShowTooltip: false,
      popper: undefined,
      timerTooltipClose: undefined,
    };
  },
  computed: {
    ariaDescribedbyForHtmlTooltip() {
      return `${ this.id }_tooltip`;
    },

    popperOptions() {
      return {
        placement: this.tooltipPlacement,
        removeOnDestroy: true,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -5],
            },
          },
        ],
      };
    },
  },
  beforeUnmount() {
    this.destroyPopper();
    this.destroyEventBusCloseTooltip();
  },
  methods: {
    showTooltip() {
      if (this.statusShowTooltip) {
        clearTimeout(this.timerTooltipClose);
        return;
      }
      this.statusShowTooltip = true;
      setTimeout(() => {
        if (!this.popper) {
          const TOOLTIP_ELEMENT = document.getElementById(this.idForTooltipContent);
          if (!TOOLTIP_ELEMENT) {
            return;
          }
          this.popper = createPopper(
            this.$el,
            TOOLTIP_ELEMENT,
            this.popperOptions,
          );
        }
        EventBus.$emit("closeHtmlTooltip");
        this.setEventBusCloseTooltip();
      });
    },

    setEventBusCloseTooltip() {
      EventBus.$on("closeHtmlTooltip", this.closeTooltip);
    },

    destroyEventBusCloseTooltip() {
      EventBus.$off("closeHtmlTooltip", this.closeTooltip);
    },

    closeTooltipWithTimer() {
      this.timerTooltipClose = setTimeout(() => {
        this.closeTooltip();
      }, 300);
    },

    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = undefined;
      }
    },

    closeTooltip() {
      clearTimeout(this.timerTooltipClose);
      this.destroyPopper();
      this.destroyEventBusCloseTooltip();
      this.statusShowTooltip = false;
    },

    mouseEnterTooltip() {
      clearTimeout(this.timerTooltipClose);
    },

    mouseLeaveTooltip() {
      this.closeTooltipWithTimer();
    },

    updateTooltip() {
      if (this.popper) {
        this.popper.forceUpdate();
        this.setContentSymbolsLengthInTooltipContent();
      }
    },

    updateTooltipOptions() {
      if (this.popper) {
        this.popper.setOptions(this.popperOptions);
        this.setContentSymbolsLengthInTooltipContent();
      }
    },

    setContentSymbolsLengthInTooltipContent() {
      if (this.$refs.popper_content) {
        this.$refs.popper_content.setContentSymbolsLength();
      }
    },
  },
};
