import {
  computed,
} from "vue";
import {
  navbarFixedHeight,
} from "./LayoutAPI";

export default function StyleAPI() {
  const wrapperContentStyle = computed(() => {
    return {
      "padding-top": `${ navbarFixedHeight.value }px`,
    };
  });

  return {
    wrapperContentStyle,
  };
}
