import UiFloat from "../UiFloat/UiFloat";

// @vue/component
export default {
  name: "UiCurrency",
  mixins: [
    UiFloat
  ],
  computed: {
    suffixOption_UiFloatMixin() {
      return this.options.suffix || "€";
    },
    getInputmaskOptions() {
      return {
        alias: "currency",
        radixPoint: ",",
        groupSeparator: ".",
        autoGroup: true,
        digits: this.digits_UiFloatMixin,
        digitsOptional: false,
        placeholder: "0",
        suffix: this.suffix_UiFloatMixin,
        prefix: this.prefix_UiFloatMixin,
        rightAlign: this.inputAlignRight_UiFloatMixin,
      };
    },
  },
};
