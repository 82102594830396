import {
  escapeRegExp,
  size
} from "lodash-es";

export default function(value, searchModel = "") {
  if (size(value) === 0) {
    return "";
  }
  const SEARCH = escapeRegExp(searchModel);
  if (size(SEARCH) === 0) {
    return value;
  }
  const RE = new RegExp(SEARCH, "gi");
  return value.replace(RE, val => `<span class="search_highlight">${ val }</span>`);
}
