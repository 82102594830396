import ALink from "aloha-vue/src/ALink/ALink";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import TheAccessibilityButton from "../TheAccessibilityButton/TheAccessibilityButton.vue";
import TheGlobal from "../TheGlobal/TheGlobal.vue";
import TheModalTranslate from "../TheModalTranslate/TheModalTranslate.vue";
import TheMenuMobileButton from "../TheMenuMobileButton/TheMenuMobileButton.vue";
import TheMenuUser from "../TheMenuUser/TheMenuUser.vue";
import TheNavbarSearch from "../TheNavbarSearch/TheNavbarSearch.vue";
import TheSearchGlobal from "../TheSearchGlobal/TheSearchGlobal.vue";

import translate from "../../global/directives/translate";

import TheNavbarConfigAPI from "./compositionAPI/TheNavbarConfigAPI";
import TheNavbarStylesAPI from "./compositionAPI/TheNavbarStylesAPI";

// @vue/component
export default {
  name: "TheNavbar",
  components: {
    ALink,
    PuxIcon,
    TheAccessibilityButton,
    TheModalTranslate,
    TheMenuUser,
    TheMenuMobileButton,
    TheNavbarSearch,
    TheSearchGlobal,
    TheGlobal,
  },
  directives: {
    translate
  },
  props: {
    config: {
      type: Object,
      required: false,
      default: undefined,
    },
    isAuthenticated: {
      type: Boolean,
      required: false,
    },
    partMarked: {
      type: String,
      required: false,
      validator: value => ["left", "center", "right"].indexOf(value) !== -1,
      default: undefined,
      info: "Teil markieren",
    },
    isMock: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const {
      configLocalCenter,
      configLocalLeft,
      configLocalRight,
      configLocalRightChildrenFiltered,
    } = TheNavbarConfigAPI({
      props,
    });

    const {
      markedPartClassCenter,
      markedPartClassLeft,
      markedPartClassRight,
    } = TheNavbarStylesAPI({
      props,
    });

    return {
      markedPartClassCenter,
      markedPartClassLeft,
      markedPartClassRight,

      configLocalCenter,
      configLocalLeft,
      configLocalRight,
      configLocalRightChildrenFiltered,
    };
  },
};
