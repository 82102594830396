import AButton from "aloha-vue/src/AButton/AButton";
import MenuElementLevel2 from "../MenuElementLevel2/MenuElementLevel2.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";

import MenuElementMixin from "../MenuElementMixin";

import {
  createPopper,
} from "popperjs";
import {
  EventBus
} from "../../../global/functions/event-bus";
import {
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "MenuElementLevel1",
  components: {
    AButton,
    MenuElementLevel2,
    PuxIcon,
  },
  mixins: [
    MenuElementMixin,
  ],
  props: {
    menuParentActivePk: {
      type: String,
      required: false,
      default: undefined,
    },
    setMenuParentActivePk: {
      type: Function,
      required: true,
    },
    statusMenuActive: {
      type: Boolean,
      required: true,
    },
    stateParentActivePk: {
      type: String,
      required: false,
      default: undefined,
    },
    stateChildActivePk: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusShowChildrenMenuInactive: false,
      popper: undefined,
    };
  },
  computed: {
    liId() {
      return `li_${ this.data.pk }`;
    },

    tooltipContainer() {
      return `#${ this.liId }`;
    },

    statusHasChildren() {
      return this.children.length > 0;
    },

    children() {
      return this.data.children || [];
    },

    statusOpen() {
      return this.menuParentActivePk === this.data.pk;
    },

    childrenParentId() {
      return `parent_${ this.data.pk }`;
    },

    idForButtonWithChildren() {
      return `button_with_children_${ this.data.pk }`;
    },

    tooltipForButtonWithChildren() {
      return this.statusLink1Active ?
        "_TXT_MENU_PUNKT_WITH_CHILDREN_AUSGEWAELT_{{title}}_" :
        this.titleTranslate;
    },

    extraForButtonWithChildren() {
      return {
        title: this.titleTranslate,
      };
    },

    ariaControlsForButtonWithChildren() {
      return this.statusMenuActive ?
        this.data.pk :
        this.childrenParentId;
    },

    ariaExpandedForButtonWithChildren() {
      return this.statusMenuActive ?
        this.statusOpen :
        this.statusShowChildrenMenuInactive;
    },

    translateTextForScreenReader() {
      return this.statusLink1Active ?
        "_TXT_MENU_PUNKT_WITH_CHILDREN_AUSGEWAELT_{{title}}_" :
        this.data.label;
    },
  },
  beforeUnmount() {
    this.destroyPopper();
    this.destroyEventBus();
    this.destroyEventClickOutside();
  },
  created() {
    this.initEventBus();
  },
  methods: {
    initEventBus() {
      EventBus.$on("openMenu", this.closeMenuWithChildren);
    },

    destroyEventBus() {
      EventBus.$off("openMenu", this.closeMenuWithChildren);
    },

    setMenuParentActivePkLocal() {
      if (isFunction(this.setMenuParentActivePk)) {
        this.setMenuParentActivePk({ pk: this.data.pk });
      }
    },

    keydownLi($event) {
      if ($event.keyCode === 32 || $event.keyCode === 13) { // space und enter
        this.setMenuParentActivePkLocal();
        $event.preventDefault();
        $event.stopPropagation();
      }
    },

    onClickLink() {
      this.setStatusLinkDisabled();

      if (!this.statusOpen) {
        this.setMenuParentActivePkLocal();
      }
    },

    onClickButtonWithChildren() {
      if (this.statusMenuActive) {
        this.setMenuParentActivePkLocal();
      } else {
        this.toggleMenuWithChildren();
      }
    },

    toggleMenuWithChildren() {
      if (this.statusShowChildrenMenuInactive) {
        this.closeMenuWithChildren();
      } else {
        this.openMenuWithChildren();
      }
    },

    openMenuWithChildren() {
      this.statusShowChildrenMenuInactive = true;
      setTimeout(() => {
        if (!this.popper &&
          this.$refs.menu_children) {
          this.popper = createPopper(
            this.$refs.menu_button.$el,
            this.$refs.menu_children,
            {
              placement: "right-start",
              removeOnDestroy: true,
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                },
              ],
            },
          );
        }
        this.setEventClickOutside();
      });
    },

    closeMenuWithChildren() {
      this.statusShowChildrenMenuInactive = false;
      this.destroyPopper();
      this.destroyEventClickOutside();
    },

    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = undefined;
      }
    },

    showMenuChildren() {
      this.statusShowChildrenMenuInactive = true;
    },

    hideMenuChildren() {
      this.statusShowChildrenMenuInactive = false;
    },

    setEventClickOutside() {
      document.addEventListener("click", this.clickOutsideEvent);
    },

    destroyEventClickOutside() {
      document.removeEventListener("click", this.clickOutsideEvent);
    },

    clickOutsideEvent($event) {
      this.closeMenuWithChildren();
      $event.stopPropagation();
    },
  },
};
