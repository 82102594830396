import {
  computed,
  ref,
} from "vue";

import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

export default function ActionsAPI({
  idForVersionButton = ref(undefined),
  openModal = () => {},
} = {}) {
  const {
    checkPermissionsSync,
  } = PermissionAPI();

  const hasPermissionReleaseNotesView = computed(() => {
    return checkPermissionsSync({
      perm: "releasenotes.view",
    });
  });

  const dropdownActions = computed(() => {
    return [
      {
        type: "link",
        text: "_LINK_PROFIL_",
        to: { name: "root.nutzerprofil" },
      },
      {
        id: idForVersionButton.value,
        type: "button",
        text: "_TXT_VERSIONSINFORMATION_",
        class: "test_version",
        isHidden: !hasPermissionReleaseNotesView.value,
        callback: openModal,
      },
      {
        id: "btn_logout",
        type: "link",
        title: "_BTN_ABMELDEN_TITLE_",
        text: "_BTN_ABMELDEN_",
        class: "test_version",
        icon: "logout",
        to: {
          name: "root.logout",
        },
      },
    ];
  });

  return {
    dropdownActions,
  };
}
