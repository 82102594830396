import {
  computed,
  toRef,
} from "vue";

import {
  setFocusToTinymce
} from "aloha-vue/src/ui/ATinymce/compositionAPI/ATinymceAPI";

import {
  isFunction,
  isPlainObject,
  size,
} from "lodash-es";

export default function LinkAPI(props, {
  idLocal = computed(() => ""),
}) {
  const errorKeyOrIndex = toRef(props, "errorKeyOrIndex");
  const errorLabels = toRef(props, "errorLabels");
  const goToError = toRef(props, "goToError");
  const isErrorLink = toRef(props, "isErrorLink");

  const isErrorLinkLocal = computed(() => {
    return isPlainObject(errorLabels.value) &&
      errorLabels.value._link &&
      isErrorLink.value;
  });

  const isCKEditor = ({ element }) => {
    return element.prop("tagName") === "TEXTAREA" &&
      element.css("display") === "none";
  };

  const isTinymce = ({ element }) => {
    return element.prop("tagName") === "TEXTAREA" &&
      element.prop("class").includes("a_textarea_tinymce") &&
      element.css("display") === "none";
  };

  const goToCKEditor = ({ id }) => {
    const ELEMENT = $(`#${ id } + div .cke_editable`);
    if (ELEMENT) {
      ELEMENT.focus();
    }
  };

  const goToTinymce = ({ id }) => {
    setFocusToTinymce({ id });
  };

  const goToErrorLocal = () => {
    if (isFunction(goToError.value)) {
      return goToError.value({
        id: idLocal.value,
        key: errorKeyOrIndex.value
      });
    }
    if (size(idLocal.value) > 0) {
      const ELEMENT = $(`#${ idLocal.value }`);
      if (ELEMENT.length) {
        if (isTinymce({ element: ELEMENT })) {
          goToTinymce({ id: idLocal.value });
          return;
        }
        if (isCKEditor({ element: ELEMENT })) {
          goToCKEditor({ id: idLocal.value });
          return;
        }
        ELEMENT.focus();
      }
    }
  };

  return {
    goToErrorLocal,
    isErrorLinkLocal,
  };
}
