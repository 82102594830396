import AButton from "aloha-vue/src/AButton/AButton";

import {
  EventBus,
} from "../../global/functions/event-bus";

// @vue/component
export default {
  name: "TheMenuMobileButton",
  components: {
    AButton,
  },
  setup() {
    const openMenu = () => {
      EventBus.$emit("openMobileMenu");
    };

    return {
      openMenu,
    };
  },
};
