import translate from "../../../../directives/translate";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../mixins/FiltersMixin";

import {
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAutomaticReadOnly",
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    UIReadOnlyMixin,
  ],
  computed: {
    fieldClass() {
      return [
        this.className,
        this.options.type === "regex_config" ? "code-content" : "",
      ];
    },

    translationMsgWarningLowerMin() {
      return {
        title: "_MSG_UI_AUTOMATIC_READONLY_NUMBER_WARNING_TITLE_UNDER_MIN_{{min}}_",
        extra: { min: this.options.min_value }
      };
    },

    translationMsgWarningHigherMax() {
      return {
        title: "_MSG_UI_AUTOMATIC_READONLY_NUMBER_WARNING_TITLE_ABOVE_MAX_{{max}}_",
        extra: { max: this.options.max_value }
      };
    },

    translationMsgWarningLowerMinMax() {
      return {
        title: "_MSG_UI_AUTOMATIC_READONLY_NUMBER_WARNING_TITLE_UNDER_MIN_MAX_{{min}}_{{max}}_",
        extra: { min: this.options.min_value, max: this.options.max_value }
      };
    },

    translationMsgWarningHigherMinMax() {
      return {
        title: "_MSG_UI_AUTOMATIC_READONLY_NUMBER_WARNING_TITLE_ABOVE_MIN_MAX_{{min}}_{{max}}_",
        extra: { min: this.options.min_value, max: this.options.max_value }
      };
    },

    modelLocal() {
      if (this.options.type === "decimal" || this.options.type === "math_expression" || this.options.type === "decimal_signal") {
        return this.filterCurrency(this.model, "", this.options.decimal_places);
      }
      if (this.options.type === "date_expression") {
        return this.filterDate(this.model, "DD.MM.YYYY");
      }
      if (isNil(this.model) || this.model === "") {
        return this.options.showEmpty || "";
      }
      return this.model;
    },

    warning() {
      if (this.options.hideWarning) {
        return undefined;
      }
      if (this.options.type === "decimal" || this.options.type === "math_expression" || this.options.type === "decimal_signal") {
        const min = isNil(this.options.min_value) ? Number.NEGATIVE_INFINITY : Number(this.options.min_value);
        const max = isNil(this.options.max_value) ? Number.MAX_VALUE : Number(this.options.max_value);
        const value = Number(this.model);
        if (!isNil(this.model) && !isNil(value)) {
          if (value > max) {
            if (min !== Number.NEGATIVE_INFINITY) {
              return this.translationMsgWarningHigherMinMax;
            }
            return this.translationMsgWarningHigherMax;
          } else if (value < min) {
            if (max !== Number.MAX_VALUE) {
              return this.translationMsgWarningLowerMinMax;
            }
            return this.translationMsgWarningLowerMin;
          }
        }
      }
      return undefined;
    },
  },
};
