import {
  ref,
} from "vue";

import {
  get,
} from "lodash-es";

export const asideFixedHeight = ref(0);
export const navbarFixedHeight = ref(0);
export const isFooterFixed = ref(false);
const footer = ref(undefined);
const navbarFixedTop = ref(undefined);

export const adjustHeights = () => {
  navbarFixedHeight.value = get(navbarFixedTop.value, "offsetHeight", 0);
  const visibleFooterHeight = isFooterFixed.value ? get(footer.value, "offsetHeight", 0) : 0;
  asideFixedHeight.value = window.innerHeight - navbarFixedHeight.value - visibleFooterHeight;
};

export const toggleFixedFooter = () => {
  const WINDOW_HEIGHT = window.innerHeight;
  const FOOTER_TOP = get(footer.value?.getBoundingClientRect(), "top", WINDOW_HEIGHT);
  isFooterFixed.value = FOOTER_TOP <= WINDOW_HEIGHT;
  adjustHeights();
};

export default function LayoutAPI() {
  const resizeObserver = new ResizeObserver(() => {
    adjustHeights();
  });

  const initResizeObserver = () => {
    resizeObserver.observe(navbarFixedTop.value);
  };
  const initEventListener = () => {
    window.addEventListener("resize", adjustHeights);
  };

  return {
    adjustHeights,
    asideFixedHeight,
    footer,
    initEventListener,
    initResizeObserver,
    navbarFixedHeight,
    navbarFixedTop,
  };
}
