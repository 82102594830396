import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import FiltersAPI from "../../global/compositionAPI/FiltersAPI";
import MainAPI from "./compositionAPI/MainAPI";
import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

import Routing from "../../const/Routing";
import {
  isFooterFixed,
  toggleFixedFooter,
} from "../../App/compositionAPI/LayoutAPI";
import {
  filter,
} from "lodash-es";


// @vue/component
export default {
  name: "TheProandiFooter",
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    const {
      getHttp,
    } = AHttpAPI();

    const {
      filterDate,
    } = FiltersAPI();

    const {
      getPanelFooterClass,
    } = MainAPI();

    return {
      filterDate,
      getPanelFooterClass,
      getSyConfigsValue,
      getHttp,
      isFooterFixed,
    };
  },
  data() {
    return {
      user: undefined,
      footer: {
        text: "&#169; ProUnix 2018",
        links: [
          {
            label: "Impressum",
            internal: true,
            url: "impressum"
          },
          {
            label: "Datenschutz",
            internal: true,
            url: "datenschutz"
          },
          {
            label: "Kontakt",
            internal: true,
            url: "kontakt"
          },
          {
            label: "Hilfen",
            internal: true,
            url: "hilfen",
            after_registration: true
          },
        ],
        show_version: false,
        show_version_release_date: false,
      },
      fixedVerticalHeightDiff: 0,
      footerTopDiffAbs: 0,
      version: undefined,
      versionReleaseDate: undefined,
    };
  },
  computed: {
    filteredLinks() {
      return filter(this.footer.links, item => !item.after_registration || this.showLink);
    },

    showLink() {
      return !!this.isAuthenticated;
    },

    versionReleaseDateFormatted() {
      return this.filterDate(this.versionReleaseDate);
    },
  },
  mounted() {
    this.setHeightForFixedVertical();
    if ("IntersectionObserver" in window) {
      this.initToggleFooter();
    } else {
      this.initToggleFooterIE();
    }
    window.addEventListener("resize", this.setHeightForFixedVertical);
  },
  created() {
    this.initData();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.setHeightForFixedVertical);
    window.removeEventListener("resize", this.setHeightForFixedVertical);
  },
  methods: {
    initData() {
      this.initGetVersion();
      const footer = this.getSyConfigsValue("footer");
      if (footer) {
        this.footer = footer;
      }
    },

    initGetVersion() {
      this.getHttp({
        url: "meta/sysinfo/",
      }).then(
        response => {
          this.version = response.vin_versionsnummer;
          this.versionReleaseDate = response.vin_datum;
        },
      );
    },

    initToggleFooterIE() {
      window.addEventListener("scroll", this.setHeightForFixedVertical);
    },

    initToggleFooter() {
      const options = {
        rootMargin: "0px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(this.toggleFooter, options);
      observer.observe(this.$el);
    },

    setHeightForFixedVertical() {
      toggleFixedFooter();
    },

    toggleFooter(entries) {
      if (entries[0].isIntersecting) {
        this.setHeightForFixedVertical(true);
      } else {
        this.setHeightForFixedVertical();
      }
    },

    getLink(url) {
      return Routing[url] || url;
    },
  },
};
